import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = []

const content = () => (
  <div>
    <style>
      {
        "\
          .pressDetailPage .pressDetailBody .contentArea .descImage img {\
            margin: auto;\
            width: 330px;\
          }\
        "
      }
    </style>
    <div className="descImage promotion">
      <div className="img">
        <img src="/images/press/6/jQeapeJp.jpg" alt="Figure1" />
      </div>
      <div className="desc">
        △사진은 개포동에 위치한 이노베이션 아카데미 클러스터에서의 교육생 모습.
        <br />
        현재는 사회적 거리두기 2.5단계로 모든 교육생들이 온라인 학습 중이다. (사진제공 이노베이션 아카데미)
      </div>
    </div>
    <br />
    <p>
      <br />
      ㅣ 스테이지파이브(공동대표 이제욱, 서상원)가 오늘(22일) 이노베이션 아카데미(학장 이민석)와 디지털 인재양성 분야 상호협력을
      위한 양해각서(MOU)를 교환했다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 양사는 창의적 소프트웨어 인재 양성, 개발자 생태계 활성화 공동 사업과제를 추진한다. 협약 내용은 △기업협력 공동 프로젝트
      진행 및 현업 개발자 멘토링 지원 △우수 교육생 인턴 및 직원 채용△공동 세미나 및 소프트웨어 개발 분야 관련 이벤트 진행 △보유
      네트워크 공유 등이다.
      <br />
    </p>
    <br />
    <p>
      | 스테이지파이브는 이번 MOU를 기점으로 관련분야 취업 희망자를 위한 비대면 멘토링을 시작할 예정이다. 또한 아카데미 측과
      협의하여 소프트웨어 개발자를 위한 데이터 기반 팀별 해커톤 등 특화된 이벤트도 계획 중이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 스테이지파이브는 2017년 카카오 공동체에 편입되어 카카오 서비스를 기반으로 차세대 통신사업을 이끌어가고 있다. 또한 2020년
      8월 ICT규제샌드박스 사업을 개시하며 통신플랫폼 서비스 ‘핀다이렉트샵’을 런칭했으며, 이동통신재판매서비스 ‘핀플레이’ 등을
      운영하고 있다. 통신 및 콘텐츠를 융합한 전문 거버넌스 체제를 구축하고, 이를 기반으로 스마트폰 뿐 아니라 IoT 전반에 걸친
      비대면 통신 플랫폼을 개발하는 등 응용소프트웨어를 개발하고 공급하고 있다.
      <br />
    </p>
    <br />
    <p>
      | 이노베이션 아카데미는 경쟁력 있는 소프트웨어 인재 양성을 위해 정부에서 설립한 혁신 교육기관이다. 2년 비학위 과정에
      교수ㆍ교재ㆍ학비가 없는 것이 특징이다. 프랑스 에꼴42의 글로벌 캠퍼스 ‘42서울’ 운영을 비롯해 새로운 소프트웨어 학습 시스템
      개발 및 소프트웨어 개발자 생태계 활성화를 위한 다양한 사업을 진행하고 있다.
      <br />
    </p>
    <br />
    <p>
      | 한편, 스테이지파이브는 개발자를 상시 모집한다. 모집부문은 △프론트엔드 △백엔드 △서버 등이며, 각 부문 0명 규모로 진행된다.
      이번 채용은 스테이지파이브의 가파른 상승세에 발맞춰 서비스 고도화를 위해 진행된다. 학력 및 전공에 제한을 두지 않고 지원자의
      역량을 최우선으로 심사한다.
      <br />
    </p>
  </div>
)

const press6 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2020년 12월 22일 즉시배포 가능."
      pageInfo="총 2매/ 첨부: 사진 2매"
      title="ㅣ 스테이지파이브, 이노베이션 아카데미와 MOU 체결"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press6
